<script setup lang="ts">
import MenuTooltipList from '@/components/Menu/MenuTooltipList.vue'
import Divider from '@/components/partials/Divider.vue'
import MenuTooltipAccount from '@/components/Menu/MenuTooltipAccount.vue'

const emit = defineEmits(['close-tooltip'])

function close() {
    emit('close-tooltip')
}
</script>

<template>
  <div class="menu-tooltip" data-test-id="menu-tooltip">
    <MenuTooltipAccount @close-tooltip="close" />

    <div class="menu-tooltip__divider">
      <Divider type="dashed" />
    </div>

    <MenuTooltipList />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.menu-tooltip {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(var(--app-height) - 61px - env(safe-area-inset-top) - 3rem);

  @include lg-up {
    width: 18rem;
    height: auto;

    // Remove padding top on account button
    margin-top: -1rem;
  }

  &__divider {
    margin-bottom: 1rem;
  }

}
</style>
