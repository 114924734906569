<template>
  <div
    class="warning"
    :class="{
      ['warning--' + type]: type,
      'cursor-pointer': pointerCursor,
    }"
    :data-test-id="dataTestId"
    @click.stop="(e) => $emit('click', e)"
  >
    <Icon
      v-if="icon"
      :name="icon"
    />
    <span class="warning__content"><slot /></span>
  </div>
</template>

<script>
// Component imports
import Icon from '@/components/partials/Icon.vue'

export default {
  name: 'Warning',

  components: { Icon },

  props: {
    loading: { type: Boolean, default: false },
    icon: { type: String, default: 'warning' },
    type: { type: String, default: 'neutral' },
    pointerCursor: { type: Boolean, default: false },
    dataTestId: { type: String, default: null },
  },

  emits: ['click']
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';

.warning {
  border: 1px solid #c5d4e4;
  background-color: #e1f1ff;
  padding: 1rem 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;

  @include icon-svg {
    margin: 0.25rem 1rem 0 0;
    flex-shrink: 0;
  }

  @include line-lg;

  &:deep(span.b-icon-icon) {
    margin-top: 0;
  }

  &--success {
    border: 1px solid var(--color-warning-success-border);
    background-color: var(--color-warning-success-background);
  }

  &--error {
    border: 1px solid var(--color-warning-error-border);
    background-color: var(--color-warning-error-background);
  }

  &--warning {
    border: 1px solid var(--color-warning-warning-border);
    background-color: var(--color-warning-warning-background);
  }
}
</style>
