<template>
  <div v-if="review"
       class="footer-reviews"
  >
    <img src="/assets/img/footer/trustpilot-star.svg" alt="Trustpilot">
    <div>
      {{ t('footer.review.title') }}<br>
      <i18n-t keypath="footer.review.value"
              scope="global"
      >
        <template #value>
          <b>{{ review.ratingValue }}</b>
        </template>
      </i18n-t>
      -
      <i18n-t keypath="footer.review.count"
              scope="global"
              tag="a"
              target="_blank"
              class="link"
              href="https://nl.trustpilot.com/review/bistroo.nl"
              rel="noopener"
      >
        <template #count>
          {{ review.reviewCount }}
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHttp } from '@/composables/useHttp'

const { t } = useI18n()
const review = ref<{ ratingValue: number, reviewCount: number } | null>(null)
const http = useHttp()

try {
  const response = await http.get('pilot-tunnel')

  review.value = {
    ratingValue: response.data.businessUnit.stars,
    reviewCount: response.data.businessUnit.numberOfReviews.total,
  }
} catch (error) {
  //
}

</script>

<style lang="scss" scoped>
@import "@/assets/css/variables/variables.scss";

.footer-reviews {
  display: flex;
  gap:     1rem;
  line-height: $line-height-md;
}
</style>
